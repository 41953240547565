












































































import {Component, Ref, Vue} from "vue-property-decorator"
import StringTool from "@/services/tool/StringTool"
import LoginService from "@/services/LoginService"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import Session from "@/models/Session"
import ProfileTool from "@/services/tool/ProfileTool"
import LangModule from "@/store/LangModule"
import PasswordService from "@/services/PasswordService"

@Component
export default class LoginView extends Vue {
	@Ref() readonly form!: HTMLFormElement
	@Ref() readonly requestRecoveryForm!: HTMLFormElement
	@Ref() readonly loginButton!: HTMLButtonElement
	sessionModule: SessionModule = getModule(SessionModule)
	lang: any = getModule(LangModule).lang
	email: string = ""
	password: string = ""
	showPassword: boolean = false
	progress: boolean = false
	loginButtonReset = 1
	dialog: boolean = false
	recoveryEmail: string = ""

	emailRules = [
		(v: string) => v.length > 0 ? true : this.lang.emailRequired,
		(v: string) => StringTool.validateEmail(v) ? true : this.lang.emailNoValid
	]

	passwordRules = [
		(v: string) => v.length > 0 ? true : this.lang.requiredPassword
	]

	created() {
		this.sessionModule.setSession(new Session());
		this.sessionModule.saveSession()
	}

	async requestRecovery() {
		if (this.requestRecoveryForm.validate()) {
			await PasswordService.requestRecovery(this, this.recoveryEmail)
			this.dialog = false
		}
	}

	login() {
		if (this.form.validate()) {
			LoginService.postLogin(this, this.email, this.password, getModule(SnackbarModule), this.sessionModule)
		}
	}

	count: number = 0
	rotation: number = 0
	developLogin() {
		this.count++
		if (this.count % 3 == 0) {
			switch (this.rotation) {
				case 0: this.email = "admin@mercurio.com"; break
				case 1: this.email = "admin2@mercurio.com"; break
				case 2: this.email = "admin3@mercurio.com"; break
			}
			this.password = "1234"
			this.rotation++
			if (this.rotation > 2) this.rotation = 0
		}
	}
}
